import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Index from "./containers/Index";
import Search from "./containers/Search";
import Faq from "./containers/Faq";
import NotFound from "./containers/NotFound"; // Import de la page NotFound
import axios from "axios";

function App() {
  const [token, setToken] = useState(null);

  const verifyToken = (token) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/verify-token`, { token })
      .then(response => {
        const isValid = response.data.success;
        if (isValid) {
          console.log('Token is valid');
          return true;
        } else {
          console.log('Token is invalid');
          return false;
        }
      })
      .catch(error => {
        console.error('Error during token verification:', error);
        return false;
      });
  };

  const getToken = async () => {
    const localToken = localStorage.getItem('token');
    if (localToken) {
      const isValid = await verifyToken(localToken);
      if (!isValid) {
        // Si le token local n'est pas valide, demandez un nouveau token
        requestNewToken();
      } else {
        // Le token local est valide, vous pouvez continuer à l'utiliser
        setToken(localToken);
      }
    } else {
      // Pas de token local, demandez un nouveau token
      requestNewToken();
    }
  };

  const requestNewToken = () => {
    const payload = { userId: process.env.REACT_APP_USER_ID_TOKEN };
    axios.post(`${process.env.REACT_APP_API_URL}/get-token`, { payload })
      .then(response => {
        const newToken = response.data.token;
        setToken(newToken);
        localStorage.setItem('token', newToken);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération du token:', error);
        // Ajoutez ici un comportement spécifique en cas d'erreur de récupération du token
      });
  };

  useEffect(() => {
    getToken();
  }, [token]);

  return (
    <Router>
      <Switch>
        <Route exact path="/search" component={Search} />
        <Route exact path="/" component={Index} />
        <Route exact path="/faq" component={Faq} />
        <Route component={NotFound} /> {/* Route pour la page NotFound - aucune correspondance de chemin */}
      </Switch>
    </Router>
  );
}

export default App;