import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import "../styles/styles.css";
import "../styles/button.css";
import "../styles/text.css";
import "../styles/faq.css";
import axios from "axios";
import Mountain from "../images/mountain.svg";

function Faq() {
  const [faq, setFaq] = useState([
    {
      _id: "",
      title: "",
      content: "",
      createdAt: "",
      updatedAt: "",
    },
  ]);
  const token = localStorage.getItem('token');
  const [noData, setNoData] = useState("");
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/faq`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        setFaq(res.data);
        setNoData("");
        if (res.data.length == 0) {
          setNoData("Pas encore de FAQ");
        }
      })
      .catch((err) => {

      });
  }, []);
  const backText = "< Retour";
  return (
    <div id="scroll">
      <img src={Mountain} className="mountain-bg" alt="Mountain" />
      <div className="faq">
        <Navbar page="faq" />
        <div className="faq-content">
          <div className="faq-title">
            <Link to="/" className="active">
              {backText}
            </Link>
            <h2>FAQ</h2>
          </div>
          {faq.map((faqItem) => (
            <div key={faqItem._id} className="faq-item">
              <h3>{faqItem.title}</h3>
              <p>{faqItem.content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Faq;
