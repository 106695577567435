import React from "react";
import Navbar from "../components/Navbar";
import Mountain from "../images/mountain.svg";
import Shapes from "../images/shapes.svg";
import NotFoundImage from "../images/not-found.png";
import "../styles/not-found.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function NotFound() {
    // const backText = "< Retour";
    return (
        <div>
            <img src={Mountain} className="mountain-bg" alt="Mountain" />
            <img src={Shapes} className="shapes-bg" alt="Shapes" />
            <div className="not-found">
                <Navbar className="not-found-header" page="not-found" />
                <div className="not-found-content">
                    <h1>404 : Oups !</h1>
                    <img src={NotFoundImage} alt="NotFound" /> {/* Cette image sera maintenant responsive */}
                    <p>Nous avons cherché partout, même sous le canapé. Pas de traces de cette page ici.</p>
                    <p>Vous pouvez retourner à la <Link to="/">page d'accueil</Link>.</p>
                </div>
            </div>
        </div>
    );
}

export default NotFound;
