import React, { Fragment, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Navbar from "../components/Navbar";
import Mountain from "../images/mountain.svg";
import Shapes from "../images/shapes.svg";
import CardHand from "../images/card-hand.svg";
import Cross from "../images/cross.svg";
import Calendar from "../images/calendar.svg";
import Id from "../images/id.svg";
import "../styles/home.css";
import "../styles/modal.css";
import axios from "axios";
import emailjs from '@emailjs/browser';
import { DatePickerInput, DatePicker } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Puff } from "react-loader-spinner";

toast.configure();
function Index() {
  const [modalActive, setModalActive] = useState(false);
  const [newData, setNewData] = useState({
    lname: "",
    fname: "",
    cni: "",
    dob: "",
    photo: "",
    city: "",
    district: " ",
    telephone: " ",
    email: "",
    description: "",
  });
  const [selectedFile, setSelectedFile] = useState("Ajouter un fichier");
  const [fileError, setFileError] = useState(false);
  const [switched, setSwitched] = useState(false);
  const [error, setError] = useState(false);
  const [showErr, setShowErr] = useState("");
  const [dob, settDob] = useState(false);
  const [fillData, setFillData] = useState(false);
  const [lnameErr, setLnameErr] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [fnameErr, setFnameErr] = useState(false);
  const [cniErr, setCniErr] = useState(false);
  const [dobErr, setDobErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [imageErr, setImageErr] = useState(false);
  const token = localStorage.getItem('token');
  const [loader, setLoader] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [telephoneErr, setTelephoneErr] = useState(false);

  // console.log('token', token);


  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };
  const validateTelephone = (telephone) => {
    const re = /^(\+237)?[2-6]\d{8}$/;
    return re.test(telephone);
  };
  const validateDob = (date) => {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age >= 15;
  };

  const validateData = () => {
    if (
      !fileError &&
      newData.city &&
      newData.lname &&
      newData.fname &&
      newData.cni &&
      newData.dob &&
      newData.description
    ) {
      setShowErr("");
      setCityErr(false)
      handleSubmit();
    } else {
      setShowErr("Veuillez remplir tous les champs obligatoires");
      setCityErr(true)
      // setShowErr("La ville est obligatoire");
    }
  };



  const setDob = (jsDate) => {
    jsDate = jsDate.toString();
    let date = jsDate.split(" ");
    let day = date[2].length < 2 ? "0" + date[2] : date[2];
    const formattedDate = `${setMonth(date[1])}/${day}/${date[3]}`;
    setNewData({
      ...newData,
      dob: formattedDate,
    });
    // setDobErr(!validateDob(formattedDate));  // Ajout de la validation ici
  };

  const convertDateFormat = (dateString) => {
    var parts = dateString.split('/');
    return parts[1] + '/' + parts[0] + '/' + parts[2];
  };

  const setMonth = (test) => {
    if (test === "Jan") {
      return "01";
    } else if (test === "Feb") {
      return "02";
    } else if (test === "Mar") {
      return "03";
    } else if (test === "Apr") {
      return "04";
    } else if (test === "May") {
      return "05";
    } else if (test === "Jun") {
      return "06";
    } else if (test === "Jul") {
      return "07";
    } else if (test === "Aug") {
      return "08";
    } else if (test === "Sep") {
      return "09";
    } else if (test === "Oct") {
      return "10";
    } else if (test === "Nov") {
      return "11";
    } else if (test === "Dec") {
      return "12";
    }
  };

  const handleSubmit = () => {
    setLoader(true)
    const formData = new FormData();
    formData.append("lname", newData.lname);
    formData.append("fname", newData.fname);
    formData.append("cni", newData.cni);
    formData.append("dob", newData.dob);
    formData.append("photo", newData.photo);
    formData.append("city", newData.city);
    formData.append("district", newData.district);
    formData.append("telephone", newData.telephone);
    formData.append("email", newData.email);
    formData.append("description", newData.description);
    // console.log("Data", formData);
    // console.log("Link", `${process.env.REACT_APP_API_URL}/addCNI`);
    axios
      .post(`${process.env.REACT_APP_API_URL}/addCNI`, formData,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setLoader(false)
          clearData();
          closeModal();
          closeSwitch();
          toast.success("CNI enregistrée avec succès", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 9000,
            style: {
              backgroundColor: '#5c993e',
              color: 'white',
            },
            draggable: false,
          });
          if (res.data.data.length > 0) {
            // Convertir le prénom de telle sorte que la première lettre soit en majuscule et le reste en minuscules
            const formattedFirstName = res.data.data[0].firstname.charAt(0).toUpperCase() + res.data.data[0].firstname.slice(1).toLowerCase();

            // Convertir l'e-mail en minuscules
            const formattedEmail = res.data.data[0].email.toLowerCase();

            // console.log({ to_name: formattedFirstName, to_email: formattedEmail });

            const templateParams = {
              to_email: formattedEmail,
              to_name: formattedFirstName,
            };

            emailjs.send(process.env.REACT_APP_SERVICE_KEY, process.env.REACT_APP_TEMPLATE_KEY, templateParams, process.env.REACT_APP_PUBLIC_KEY)
              .then((response) => {
                // console.log('E-mail envoyé avec succès:', response);   
              })
              .catch((error) => {
                setLoader(false)
                // console.error('Erreur lors de l\'envoi de l\'e-mail:', error); 
              });
          }

        }
      })
      .catch((err) => {
        setLoader(false)
        closeSwitch();
      });
  };

  const clearData = () => {
    settDob(false);
    setSelectedFile("Ajouter un fichier");
    setFileError(false);
    setSwitched(false);
    setError(false);
    setTelephoneErr(false)
    setDobErr(false)
    setEmailErr(false)
    setShowErr("");
    setNewData({
      lname: "",
      fname: "",
      cni: "",
      dob: "",
      photo: "",
      city: "",
      district: "",
      telephone: "",
      email: "",
      description: "",
    });
  };

  const cross = () => {
    closeModal();
    closeSwitch();
    clearData();
  };

  const openModal = () => {
    setModalActive(true);
  };
  const closeModal = () => {
    setModalActive(false);
    clearData()
  };
  const openSwitch = () => {
    setSwitched(true);
  };
  const closeSwitch = () => {
    setSwitched(false);
  };
  // const handleChange = (e) => {
  //   setNewData({ ...newData, [e.target.name]: e.target.value });
  // };
  const handleChange = (e) => {
    setNewData({ ...newData, [e.target.name]: e.target.value });
    if (e.target.name === 'email') {
      setEmailErr(!validateEmail(e.target.value));
    }
    if (e.target.name === 'telephone') {
      setTelephoneErr(!validateTelephone(e.target.value));
    }
  };

  const handlePhoto = (e) => {
    // if (!e.target.files[0]) {
    //   setFileError(true);
    //   setSelectedFile("Veuillez choisir une image.");
    // } else 
    if ((e.target.files[0] && !e.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/))) {
      setSelectedFile("Veuillez choisir une image valide.");
      setFileError(true);
    } else {
      setFileError(false);
      setSelectedFile(e.target.files[0].name);
      setNewData({ ...newData, photo: e.target.files[0] });

    }
  };
  return (
    <Fragment>
      <div
        className={`modal-wrapper ${modalActive ? "active" : ""} ${switched ? "switch" : ""
          }`}
      >
        <div className="overlay"></div>
        {/* <div className="overlay" onClick={closeModal}></div> */}
        <div className="modal">
          <div className="modal-content modal-content-1">
            <img src={Cross} className="modal-icon" onClick={cross} alt="Cross" />
            <h2>Enregistrer une CNI</h2>
            <br />
            <div className="input-flex">
              <div className="input-wrapper">
                <p className="input-label">Nom*</p>
                {lnameErr ? (
                  <input
                    style={{ border: "2px solid red" }}
                    type="text"
                    name="lname"
                    value={newData.lname}
                    onChange={(e) => {
                      setLnameErr(false);
                      handleChange(e);
                      setFillData(false);
                    }}
                    required
                  />
                ) : (
                  <input
                    type="text"
                    name="lname"
                    value={newData.lname}
                    onChange={handleChange}
                    required
                  />
                )}
              </div>
              {fnameErr ? (
                <div className="input-wrapper">
                  <p className="input-label">Prénom*</p>
                  <input
                    style={{ border: "2px solid red" }}
                    type="text"
                    name="fname"
                    value={newData.fname}
                    onChange={(e) => {
                      setFnameErr(false);
                      handleChange(e);
                      setFillData(false);
                    }}
                  />
                </div>
              ) : (
                <div className="input-wrapper">
                  <p className="input-label">Prénom*</p>
                  <input
                    type="text"
                    name="fname"
                    value={newData.fname}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>
            <div className="input-flex" style={{ marginTop: "8px" }}>
              {cniErr ? (
                <div className="input-wrapper">
                  <p className="input-label">Numéro de CNI*</p>
                  <input
                    style={{ border: "2px solid red" }}
                    type="text"
                    name="cni"
                    value={newData.cni}
                    onChange={(e) => {
                      setCniErr(false);
                      handleChange(e);
                      setFillData(false);
                    }}
                  />
                </div>
              ) : (
                <div className="input-wrapper">
                  <p className="input-label">Numéro de CNI*</p>
                  <input
                    type="text"
                    name="cni"
                    value={newData.cni}
                    onChange={handleChange}
                  />
                </div>
              )}
              <div className="input-wrapper">
                <p className="input-label">Date de naissance*</p>
                {dobErr ? (<input
                  style={{ border: "2px solid red" }}
                  type="text"
                  name="dob"
                  value={newData.dob ? convertDateFormat(newData.dob) : newData.dob}
                  disabled={true}
                />) : (<input
                  type="text"
                  name="dob"
                  value={newData.dob ? convertDateFormat(newData.dob) : newData.dob}
                  disabled={true}
                />)}
                <img
                  src={Calendar}
                  className="input-icon"
                  onClick={() => {
                    if (dob) {
                      settDob(false);
                    } else {
                      settDob(true);
                    }
                  }}
                  alt=""
                />
                {dob ? (
                  <div style={{ zIndex: 2, position: "absolute" }}>
                    <DatePicker

                      value={newData.dob}
                      onChange={(jsDate) => {
                        setDob(jsDate);
                        settDob(false);
                        setDobErr(false);
                        setFillData(false);
                      }}
                    />
                  </div>
                ) : null}
                {/* {dobErr ? (
                      <span className="error-message" style={{bottom: "-27px"}}>Vous devez avoir au moins 15 ans.</span>
                  ) : null} */}
              </div>
            </div>

            <div className="input-wrapper" style={{ marginTop: "15px" }}>
              {descriptionErr ? (
                <div className="input-wrapper">
                  <p className="input-label">Description du lieu de Découverte et de retrait de la CNI*</p>
                  <textarea
                    rows={3}
                    style={{ border: "2px solid red" }}
                    type="text"
                    name="description"
                    value={newData.description}
                    onChange={(e) => {
                      setDescriptionErr(false);
                      handleChange(e);
                      setFillData(false);
                    }}
                  />
                </div>
              ) : (
                <div className="input-wrapper">
                  <p className="input-label">Description du lieu de retrait de la CNI*</p>
                  <textarea
                    rows={3}
                    type="text"
                    name="description"
                    value={newData.description}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>
            <div className="input-wrapper">
              <p className="input-label">Photo de la CNI</p>
              <div className="file-wrapper">
                <img src={Id} className="file-icon" alt="Id" />
                <p>{selectedFile}</p>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  name="photo"
                  onChange={handlePhoto}
                />
              </div>
            </div>
            <br />
            {fillData ? (
              <p style={{ color: "red" }}>
                Veuillez remplir tous les champs obligatoires
              </p>
            ) : null}
            <div
              className="button button-primary"
              onClick={() => {
                if (newData.lname) {
                  setLnameErr(false);
                } else {
                  setLnameErr(true);
                }
                if (newData.fname) {
                  setFnameErr(false);
                } else {
                  setFnameErr(true);
                }
                if (newData.cni) {
                  setCniErr(false);
                } else {
                  setCniErr(true);
                }
                if (newData.dob) {
                  setDobErr(false);
                } else {
                  setDobErr(true);
                }
                if (newData.description) {
                  // console.log(newData.description);
                  setDescriptionErr(false);
                } else {
                  setDescriptionErr(true);
                }
                if (
                  !fileError &&
                  newData.lname &&
                  newData.fname &&
                  newData.cni &&
                  newData.dob &&
                  newData.description
                ) {
                  settDob(false);
                  openSwitch();
                } else {
                  setFillData("Fill whole Data");
                }
              }}
            >
              <p>Suivant</p>
            </div>
          </div>
          <div className="modal-content modal-content-2">
            <img src={Cross} className="modal-icon" onClick={cross} alt="Cross" />
            <h2>Vos coordonnées</h2>
            <br />
            <div className="input-flex" style={{ marginTop: "8px" }}>
              <div className="input-wrapper">
                <p className="input-label">Ville*</p>
                {cityErr ? (
                  <input
                    style={{ border: "2px solid red" }}
                    type="text"
                    name="city"
                    value={newData.city}
                    onChange={(e) => {
                      setCityErr(false);
                      handleChange(e);
                      setFillData(false);
                    }}
                    required
                  />
                ) : (
                  <input
                    type="text"
                    name="city"
                    value={newData.city}
                    onChange={handleChange}
                    required
                  />
                )}
              </div>
              <div className="input-wrapper">
                <p className="input-label">Quartier</p>
                <input
                  type="text"
                  name="district"
                  value={newData.district}
                  onChange={handleChange}
                />
              </div>
            </div>
            <br />
            <div className="input-flex">
              <div className="input-wrapper" style={{ paddingBottom: "20px" }}>
                <p className="input-label">Téléphone</p>
                <input
                  // style={(newData.telephone &&telephoneErr) ? { border: "2px solid red" } : null}
                  type="text"
                  name="telephone"
                  value={newData.telephone}
                  onChange={(e) => {
                    setTelephoneErr(false);
                    handleChange(e);
                    setFillData(false);
                  }}

                />
                {/* {telephoneErr && (
                  <span className="error-message">
                    Veuillez entrer un numéro valide.
                  </span>
                )} */}
              </div>
              <br />
              <div className="input-wrapper" style={{ paddingBottom: "20px" }}>
                <p className="input-label">Adresse e-mail</p>
                <input
                  style={(newData.email && emailErr) ? { border: "2px solid red" } : null}
                  type="text"
                  name="email"
                  value={newData.email}
                  onChange={(e) => {
                    setEmailErr(!validateEmail(e.target.value));
                    handleChange(e);
                    setFillData(false);
                  }}

                />
                {(newData.email && emailErr) && (
                  <span className="error-message">
                    Veuillez entrer une adresse email valide.
                  </span>
                )}
              </div>
            </div>
            <br />
            <div className="modal-actions">
              <a onClick={closeSwitch}>Retour</a>
              <div className="button button-primary" onClick={validateData}>
                <p>Valider</p>
                {loader &&
                  <Puff
                    height="20"
                    width="20"
                    radius={1}
                    color="#fff"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                }
              </div>

            </div>
            {(showErr && cityErr) ? (
              <p style={{ color: "red", marginTop: 10 }}>{showErr}</p>
            ) : null}
          </div>
        </div>
      </div>
      <img src={Mountain} className="mountain-bg" alt="Mountain" />
      <img src={Shapes} className="shapes-bg" alt="Shapes" />
      <div className="home">
        <Navbar className="home-header" page="index" />
        <div className="home-content">
          <h1>Retrouvez votre CNI</h1>
          <p>
            <strong>Vous avez perdu votre CNI ?</strong>
          </p>
          <p>Cliquez sur "Je cherche ma CNI" et retrouvez-la.</p>
          <br />
          <p>
            <strong>Vous avez trouvé une CNI appartenant à un tiers ?</strong>
          </p>
          <p>
            Cliquez sur "J'ai trouvé une CNI" et sauvegardez la pour qu’elle retourne à
            son titulaire.
          </p>
        </div>
        <div className="home-cta">
          <Link to="/search" className="button button-primary">
            <p>Je cherche ma CNI</p>
          </Link>
          <div className="button button-secondary" onClick={openModal}>
            <p>J'ai trouvé une CNI</p>
          </div>
        </div>
      </div>
      <img src={CardHand} className="hand-vfx" alt="CardHand" />
    </Fragment>
  );
}

export default Index;
