import React, { Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import "../styles/styles.css";
import "../styles/text.css";
import "../styles/button.css";
import "../styles/navbar.css";

function Navbar({ page }) {
  return (
    <Fragment>
      {page == "index" ? (
        <div className="navbar1">
          <div className="navbar1-inner">
            <Link to="/">
              <div className="logo1"></div>
            </Link>
            <div className="navigation">
              <Link to="/" style={{ color: "#5c993e" }} className="nav-link">
                Accueil
              </Link>
              <Link to="/faq" className="nav-link">
                FAQ
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="navbar">
          <div className="navbar-inner">
            <Link to="/" className="nav-link">
                <div className="logo"></div>
              </Link>
            <div className="navigation">
              <Link to="/" className="nav-link">
                Accueil
              </Link>
              {page === "faq" ? (
                <Link
                  to="/faq"
                  style={{ color: "#5c993e" }}
                  className="nav-link"
                >
                  FAQ
                </Link>
              ) : (
                <Link to="/faq" className="nav-link">
                  FAQ
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Navbar;
