import React, { Fragment, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Navbar from "../components/Navbar";
import "../styles/search.css";
import "../styles/modal.css";
import Mountain from "../images/mountain.svg";
import Cross from "../images/cross.svg";
import Calendar from "../images/calendar.svg";
import Location from "../images/location.svg";
import NotFoundImage from "../images/not-found.png";
import SearchGif from "../images/search.gif";
import axios from "axios";
import { DatePickerInput, DatePicker } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Puff } from "react-loader-spinner";
toast.configure();

function Search() {
  const [modalActive, setModalActive] = useState(false);
  const [modalActive1, setModalActive1] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedFile, setSelectedFile] = useState("Ajouter un fichier");
  const [fileError, setFileError] = useState(true);
  const [switched, setSwitched] = useState(false);
  const [error, setError] = useState(false);
  const [showErr, setShowErr] = useState("");
  const [dob, settDob] = useState(false);
  const [dobD, settDobD] = useState(false);
  const [fillData, setFillData] = useState(false);
  const [lnameErr, setLnameErr] = useState(false);
  const [lnameErrD, setLnameErrD] = useState(false);
  const [fnameErr, setFnameErr] = useState(false);
  const [fnameErrD, setFnameErrD] = useState(false);
  const [cniErr, setCniErr] = useState(false);
  const [telephoneErr, setTelephoneErr] = useState(false);
  const [dobErr, setDobErr] = useState(false);
  const [dobErrD, setDobErrD] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [imageErr, setImageErr] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [loaderD, setLoaderD] = useState(false);
  const token = localStorage.getItem('token')
  // console.log("token local", token);
  const [newData, setNewData] = useState({
    lname: "",
    lnameD: "",
    fname: "",
    fnameD: "",
    cni: "",
    cniD: "",
    dob: "",
    dobD: "",
    photo: "",
    city: " ",
    district: " ",
    telephone: "",
    whatsapp: "",
    email: "",
  });
  const [index, setIndex] = useState(null);
  const backText = "< Retour";
  const [noData, setNoData] = useState("");

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };
  const validateTelephone = (telephone) => {
    const re = /^(\+237)?[2-6]\d{8}$/;
    return re.test(telephone);
  };
  const validateDob = (date) => {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age >= 15;
  };
  const setDob = (jsDate) => {
    jsDate = jsDate.toString();
    let date = jsDate.split(" ");
    setNewData({
      ...newData,
      dob: `${setMonth(date[1])}/${date[2]}/${date[3]}`,
    });
  };
  const setDobD = (jsDate) => {
    jsDate = jsDate.toString();
    let date = jsDate.split(" ");
    let day = date[2].length < 2 ? "0" + date[2] : date[2];
    const formattedDate = `${setMonth(date[1])}/${day}/${date[3]}`;
    setNewData({
      ...newData,
      // dob: formattedDate,
      dobD: formattedDate,
    });
    // setDobErrD(!validateDob(formattedDate));  // Ajout de la validation ici
  };

  const convertDateFormat = (dateString) => {
    var parts = dateString.split('/');
    return parts[1] + '/' + parts[0] + '/' + parts[2];
  };

  const setMonth = (test) => {
    if (test === "Jan") {
      return "01";
    } else if (test === "Feb") {
      return "02";
    } else if (test === "Mar") {
      return "03";
    } else if (test === "Apr") {
      return "04";
    } else if (test === "May") {
      return "05";
    } else if (test === "Jun") {
      return "06";
    } else if (test === "Jul") {
      return "07";
    } else if (test === "Aug") {
      return "08";
    } else if (test === "Sep") {
      return "09";
    } else if (test === "Oct") {
      return "10";
    } else if (test === "Nov") {
      return "11";
    } else if (test === "Dec") {
      return "12";
    }
  };

  const validateData = () => {
    settDob(false);
    if (newData.lname && newData.fname && newData.dob) {
      handleSubmit();
    } else {
      setDobErr(true)
      setFnameErr(true)
      setLnameErr(true)
    }
  };


  const handleSubmit = () => {
    setLoader(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/searchCNI`,
        {
          headers: {
            Authorization: ` Bearer ${token} `
          },
          params: {
            lname: newData.lname,
            fname: newData.fname,
            cni: newData.cni,
            dob: newData.dob,
          },
        })
      .then((res) => {
        setSearchResults([]);
        setNoData("");
        let data = res.data;
        if (res.status) {
          setLoader(false)
          if (data.length === 0) {
            setNoData(`Désolé ${newData.fname}, votre CNI n'a pas encore été archivée, veuillez réessayer plus tard.`);
          } else {
            for (let i = 0; i < data.length; i++) {
              setSearchResults((prevs) => [
                ...prevs,
                {
                  id: i,
                  lname: data[i].lastname,
                  fname: data[i].firstname,
                  cni: data[i].cni_number,
                  dob: data[i].dob,
                  photo: data[i].cni_photo,
                  city: data[i].city ? data[i].city : " Non renseigné",
                  district: data[i].district ? data[i].district : "Non renseigné ",
                  telephone: data[i].telephone ? data[i].telephone : "Non renseigné ",
                  whatsapp: data[i].whatsapp ? data[i].whatsapp : "Non renseigné ",
                  email: data[i].email ? data[i].email : " Non renseigné",
                  description: data[i].description ? data[i].description : "Non renseigné ",
                },
              ]);
            }
          }
        }

      })
      .catch((err) => {
        setLoader(false)
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };
  const cross = () => {
    closeModal1();
    closeSwitch();
    clearData();
  };

  const validateDeclaration = () => {
    console.log("before", emailErr);

    if (
      newData.lnameD &&
      newData.fnameD &&
      newData.telephone &&
      newData.dobD &&
      newData.email
    ) {
      if (validateEmail(newData.email)) {
        setEmailErr(false);
        setTelephoneErr(false);
        setShowErr("");

        handleSubmitDeclaration();
      } else {
        setEmailErr(true);

      }
    } else {
      setEmailErr(true);
      setTelephoneErr(true);
      setShowErr("Veuillez remplir tous les champs obligatoires");
    }
  };

  const handleSubmitDeclaration = () => {
    setLoaderD(true)
    const formData = new FormData();
    formData.append("lname", newData.lnameD);
    formData.append("fname", newData.fnameD);
    formData.append("cni", newData.cniD);
    formData.append("dob", newData.dobD);
    formData.append("city", newData.city);
    formData.append("district", newData.district);
    formData.append("telephone", newData.telephone);
    formData.append("email", newData.email);
    // console.log("Data", formData);
    const requestData = {
      lastname: newData.lnameD,
      firstname: newData.fnameD,
      cni_number: newData.cniD,
      dob: newData.dobD,
      city: newData.city,
      district: newData.district,
      telephone: newData.telephone,
      email: newData.email,
    }
    // console.log("requestData", requestData);

    // return
    // console.log("Link", `${process.env.REACT_APP_API_URL}/declaration`);
    axios
      .post(`${process.env.REACT_APP_API_URL}/declaration`, requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((res) => {
        if (res.status === 201) {
          setLoaderD(false)
          clearData();
          closeModal1();
          closeSwitch();
          toast.success("Déclaration enregistrée avec succès", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 9000,
            style: {
              backgroundColor: '#5c993e',
              color: 'white',
            },
            draggable: false,
          });

        }

      })
      .catch((err) => {
        setLoaderD(false)
        // clearData();
        // closeModal();
        closeSwitch();
        toast.error("Echec, veuillez rééssayer", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      });
  };

  const clearData = () => {
    settDob(false);
    setSelectedFile("Ajouter un fichier");
    setFileError(true);
    setSwitched(false);
    setError(false);
    setTelephoneErr(false)
    setDobErrD(false)
    setEmailErr(false)
    setShowErr("");
    setNewData({
      lname: "",
      lnameD: "",
      fname: "",
      fnameD: "",
      cni: "",
      cniD: "",
      dob: "",
      dobD: "",
      photo: "",
      city: "",
      district: "",
      telephone: "",
      whatsapp: "",
      email: "",
    });
  };

  const closeSwitch = () => {
    setSwitched(false);
  };
  const openSwitch = () => {
    setSwitched(true);
  };
  const openModal1 = () => {
    setModalActive1(true);
  };
  const closeModal1 = () => {
    setModalActive1(false);
    clearData()
  };
  const openModal = (id) => {
    // console.log(id);
    setIndex(id);
    setModalActive(true);
  };
  const closeModal = () => {
    setIndex(null);
    setModalActive(false);
  };
  // const handleChange = (e) => {
  //   setNewData({ ...newData, [e.target.name]: e.target.value });
  // };
  const handleChange = (e) => {
    setNewData({ ...newData, [e.target.name]: e.target.value });
    if (e.target.name === 'email') {
      setEmailErr(!validateEmail(e.target.value));
    }

  };
  return (
    <Fragment>
      <div
        className={`modal-wrapper ${modalActive1 ? "active" : ""} ${switched ? "switch" : ""
          }`}
      >
        <div className="overlay" onClick={closeModal}></div>
        <div className="modal">
          <div className="modal-content modal-content-1">
            <img src={Cross} className="modal-icon" onClick={cross} alt="Cross" />
            <h2>Déclarer une perte de CNI</h2>
            <br />
            <div className="input-flex">
              <div className="input-wrapper">
                <p className="input-label">Nom*</p>
                {lnameErrD ? (
                  <input
                    style={{ border: "2px solid red" }}
                    type="text"
                    name="lnameD"
                    value={newData.lnameD}
                    onChange={(e) => {
                      setLnameErrD(false);
                      handleChange(e);
                      setFillData(false);
                    }}
                    required
                  />
                ) : (
                  <input
                    type="text"
                    name="lnameD"
                    value={newData.lnameD}
                    onChange={handleChange}
                    required
                  />
                )}
              </div>
              <br />
              {fnameErrD ? (
                <div className="input-wrapper">
                  <p className="input-label">Prénom*</p>
                  <input
                    style={{ border: "2px solid red" }}
                    type="text"
                    name="fnameD"
                    value={newData.fnameD}
                    onChange={(e) => {
                      setFnameErrD(false);
                      handleChange(e);
                      setFillData(false);
                    }}
                  />
                </div>
              ) : (
                <div className="input-wrapper">
                  <p className="input-label">Prénom*</p>
                  <input
                    type="text"
                    name="fnameD"
                    value={newData.fnameD}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>
            <br />
            <div className="input-flex">
              <div className="input-wrapper" >
                <p className="input-label">Date de naissance*</p>

                {dobErrD ? (
                  <input
                    type="text"
                    name="dobD"
                    style={{ border: "2px solid red" }}
                    setD
                    value={newData.dobD ? convertDateFormat(newData.dobD) : newData.dobD}
                    disabled={true}
                  />
                ) :
                  (<input
                    type="text"
                    name="dobD"
                    value={newData.dobD ? convertDateFormat(newData.dobD) : newData.dobD}
                    disabled={true}
                  />)}
                <img
                  src={Calendar}
                  className="input-icon"
                  onClick={() => {
                    if (dobD) {
                      settDobD(false);
                    } else {
                      settDobD(true);
                    }
                  }}
                  alt="Calendar"
                />
                {dobD ? (
                  <div style={{ zIndex: 2, position: "absolute" }}>
                    <DatePicker
                      className="customDatePicker"
                      value={newData.dobD}
                      onChange={(jsDate) => {
                        setDobD(jsDate);
                        settDobD(false);
                        setDobErrD(false);
                        setFillData(false);
                      }}
                    />
                  </div>
                ) : null}

              </div>
              <br />
              <div className="input-wrapper">
                <p className="input-label">Numéro de CNI</p>
                <input
                  type="text"
                  name="cniD"
                  value={newData.cniD}
                  onChange={handleChange}
                />
              </div>
            </div>
            <br />
            <br />
            <div
              className="button button-primary"
              onClick={() => {
                if (newData.lnameD) {
                  setLnameErrD(false);
                } else {
                  setLnameErrD(true);
                }
                if (newData.fnameD) {
                  setFnameErrD(false);
                } else {
                  setFnameErrD(true);
                }
                if (newData.cniD) {
                  setCniErr(false);
                } else {
                  setCniErr(true);
                }
                if (newData.dobD) {
                  setDobErrD(false);
                } else {
                  setDobErrD(true);
                }

                if (

                  newData.lnameD &&
                  newData.fnameD &&
                  newData.dobD
                ) {
                  settDob(false);
                  openSwitch();
                } else {
                  setFillData("Fill whole Data");
                }
              }}
            >
              <p>Suivant</p>
            </div>
          </div>
          <div className="modal-content modal-content-2">
            <img src={Cross} className="modal-icon" onClick={cross} alt="Cross" />
            <h2>Vos coordonnées</h2>
            <br />
            <div className="input-flex">
              <div className="input-wrapper">
                <p className="input-label">Ville</p>
                <input
                  type="text"
                  name="city"
                  value={newData.city}
                  onChange={handleChange}
                />
              </div>
              <br />
              <div className="input-wrapper">
                <p className="input-label">Quartier</p>
                <input
                  type="text"
                  name="district"
                  value={newData.district}
                  onChange={handleChange}
                />
              </div>
            </div>
            <br />
            <div className="input-flex">
              <div className="input-wrapper" style={{ paddingBottom: "20px" }}>
                <p className="input-label">Téléphone*</p>
                {telephoneErr ? (
                  <input
                    style={{ border: "2px solid red" }}
                    type="text"
                    name="telephone"
                    value={newData.telephone}
                    onChange={(e) => {
                      setTelephoneErr(false);
                      handleChange(e);
                      setFillData(false);
                    }}
                    required
                  />
                ) : (
                  <input
                    type="text"
                    name="telephone"
                    value={newData.telephone}
                    onChange={handleChange}
                    required
                  />
                )}
                {/* <input
                  style={ telephoneErr ? { border: "2px solid red" } : null}
                  type="number"
                  name="telephone"
                  value={newData.telephone}
                  onChange={(e) => {
                    setTelephoneErr(false);
                    handleChange(e);
                    setFillData(false);
                  }}
                  
                /> */}

              </div>
              <br />
              <div className="input-wrapper" style={{ paddingBottom: "20px" }}>
                <p className="input-label">Adresse e-mail*</p>
                <input
                  style={((newData.email && emailErr) || emailErr) ? { border: "2px solid red" } : null}
                  type="text"
                  name="email"
                  value={newData.email}
                  onChange={(e) => {
                    setEmailErr(!validateEmail(e.target.value));
                    handleChange(e);
                    setFillData(false);
                  }}
                  required
                />
                {(newData.email && emailErr) && (
                  <span className="error-message">
                    Veuillez entrer une adresse email valide.
                  </span>
                )}
              </div>
            </div>
            <br />
            <div className="modal-actions">
              <a onClick={closeSwitch}>Retour</a>
              <div className="button button-primary"
                onClick={() => {
                  validateDeclaration()
                }}
              >
                <p>Déclarer</p>
                {loaderD &&
                  <Puff
                    height="20"
                    width="20"
                    radius={1}
                    color="#fff"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                }
              </div>
            </div>
            {(emailErr && telephoneErr && showErr) ? (
              <p style={{ color: "red", marginTop: 10 }}>{showErr}</p>
            ) : null}
          </div>
        </div>
      </div>
      <div id="scroll">
        {index != null ? (
          <div className={`modal-wrapper ${modalActive ? "active" : ""}`}>
            <div className="overlay" onClick={closeModal}></div>
            <div className="modal">
              <div
                className="modal-content modal-content-1"
                style={{ alignItems: "flex-start" }}

              >
                <img src={Cross} className="modal-icon" onClick={closeModal} alt="Cross" />
                <h2>Personne à contacter</h2>
                <br />
                <p className="modale-contact-text">
                  <span className="input-label">Ville : </span>
                  {searchResults[index].city ? searchResults[index].city : "Non renseigné"}
                </p>
                <p className="modale-contact-text">
                  <span className="input-label">Quartier : </span>
                  {searchResults[index].district ? searchResults[index].district : "Non renseigné"}
                </p>
                <p className="modale-contact-text">
                  <span className="input-label">Téléphone : </span>
                  {searchResults[index].telephone}
                </p>
                <p className="modale-contact-text">
                  <span className="input-label">Email : </span>
                  {searchResults[index].email}
                </p>
                <p className="modale-contact-text">
                  <span className="input-label">Lieu de retrait de la CNI : </span>
                  {searchResults[index].description}
                </p>
                <br />

                <div style={{ marginTop: 10, width: "100%", height: 200 }}>
                  <img
                    style={{ maxHeight: 200, maxWidth: 250 }}
                    className="item-pic"
                    src={searchResults[index].photo ? `${process.env.REACT_APP_API_URL}/images/${searchResults[index].photo}`
                      : `${process.env.REACT_APP_API_URL}/images/cn_default.png`}
                    alt="Photo CNI"
                  />
                </div>
                <p
                  style={{
                    color: "#5c993e",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={closeModal}
                >
                  Fermer
                </p>
              </div>
            </div>
          </div>
        ) : null}
        <img src={Mountain} className="mountain-bg" alt="Mountain" />
        <div className="search">
          <Navbar />
          <div className="search-content">
            <div className="search-title">
              <Link to="/" className="active">
                {backText}
              </Link>
              <h2>Rechercher une CNI</h2>
            </div>
            <div className="search-form">
              <div className="input-wrapper">
                <p className="input-label">Nom*</p>
                {lnameErr ? (
                  <input
                    style={{ border: "2px solid red" }}
                    type="text"
                    name="lname"
                    value={newData.lname}
                    onChange={(e) => {
                      setLnameErr(false);
                      handleChange(e);
                      setFillData(false);
                    }}
                    required
                  />) :
                  (<input
                    type="text"
                    name="lname"
                    value={newData.lname}
                    onChange={handleChange}
                  />)}
              </div>
              <br />
              <div className="input-wrapper">
                <p className="input-label">Prénom*</p>
                {fnameErr ? (
                  <input
                    style={{ border: "2px solid red" }}
                    type="text"
                    name="fname"
                    value={newData.fname}
                    onChange={(e) => {
                      setFileError(false);
                      setFnameErr(false)
                      handleChange(e);
                      setFillData(false);
                    }}
                    required
                  />) :
                  (<input
                    type="text"
                    name="fname"
                    value={newData.fname}
                    onChange={handleChange}
                  />)}
              </div>
              <br />
              <div className="input-wrapper">
                <p className="input-label">Numéro de CNI</p>
                <input
                  type="text"
                  name="cni"
                  value={newData.cni}
                  onChange={handleChange}
                />
              </div>
              <br />
              <div className="input-wrapper">
                <p className="input-label">Date de naissance*</p>
                {dobErr ? (
                  <input
                    type="text"
                    name="dob"
                    style={{ border: "2px solid red" }}
                    setD
                    value={newData.dob ? convertDateFormat(newData.dob) : newData.dob}
                    disabled={true}
                  />
                ) :
                  (<input
                    type="text"
                    name="dob"
                    value={newData.dob ? convertDateFormat(newData.dob) : newData.dob}
                    disabled={true}
                  />)}
                <img
                  src={Calendar}
                  className="input-icon"
                  onClick={() => {
                    if (dob) {
                      settDob(false);
                    } else {
                      settDob(true);
                    }
                  }}
                  alt="Calendar"
                />
                {dob ? (
                  <div style={{ zIndex: 2, position: "absolute" }}>
                    <DatePicker
                      // className="display week-days "
                      value={newData.dob}
                      onChange={(jsDate) => {
                        setDob(jsDate);
                        settDob(false);
                        setDobErr(false);
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <br />
              <div className="button button-primary" onClick={validateData}>
                <p>Rechercher</p>
                {loader &&
                  <Puff
                    height="20"
                    width="20"
                    radius={1}
                    color="#fff"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                }
              </div>
            </div>
          </div>

          <div className="search-result">
            {noData ?
              <div className="home-content" >
                <img src={NotFoundImage} alt="NotFound" class="noresult" />
                <h5>{noData}</h5><br />
                <div className="button button-primary" onClick={openModal1}>
                  <p>Je déclare la perte de ma CNI</p>

                </div>
              </div>

              : null}
            {
              noData ?
                null
                :
                <div className="search-gif" >
                  <img src={SearchGif} alt="SearchGif" />
                </div>
            }
            {searchResults.length === 0
              ? null
              : searchResults.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="search-item"
                    onClick={() => openModal(item.id)}
                  >
                    <div style={{ width: "100%", height: 200 }}>
                      <img
                        style={{ maxHeight: 200 }}
                        className="item-pic"
                        src={item.photo ? `${process.env.REACT_APP_API_URL}/images/${item.photo}`
                          : `${process.env.REACT_APP_API_URL}/images/cn_default.png`}
                        alt="Photo CNI"
                      />
                    </div>
                    <p className="search-h1">
                      <strong>{`${item.fname} ${item.lname}`}</strong>
                    </p>
                    <p className="search-h2">{item.dob ? convertDateFormat(item.dob) : item.dob}</p>
                    <div className="search-item-location">
                      <img src={Location} alt="Location" />
                      <p className="search-h3">
                        <strong>{item.city}</strong>
                      </p>

                    </div>
                    <span className="search-h2" >
                      <a>
                        Consulter les détails

                      </a>
                    </span>
                  </div>
                );
              })}
            {/* <div className="search-item" onClick={openModal}>
                  <img
                    className="item-pic"
                    src="https://p9.storage.canalblog.com/91/94/828954/105761870.jpg"
                  />
                  <p className="search-h1">
                    <strong>Meyong Bertini</strong>
                  </p>
                  <p className="search-h2">2020-09-16</p>
                  <div className="search-item-location">
                    <img src={Location} />
                    <p className="search-h3">
                      <strong>Kribi</strong>
                    </p>
                  </div>
                </div> */}
          </div>
        </div>
      </div>

    </Fragment>

  );
}

export default Search;